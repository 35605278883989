import { EditableContentFormat, CaptionOptionFormat } from '../types/EditableContentFormat'

export class ContentUtils {
  static createEmptyContent(tenantId: string, projectId: string): EditableContentFormat {
    return {
      clipId: '',
      tenantId: tenantId,
      projectId: projectId,
      status: 'projectOnly',
      title: '',
      description: '',
      deliveryFormat: 'download',
      file: {
        uploads: [],
        generates: []
      },
      streamingData: {
        title: '',
        version: 'LightPlayer ContentFormat v1.1',
        description: '',
        authorName: 'MasterVisions Inc.',
        createdDate: '2020-08-05T16:12:00.000+09:00',
        frameRate: 0,
        poster: '',
        contentType: 'recorded',
        duration: 'auto',
        offsetTime: 0,
        modes: ['MultiSelect'],
        playerOptions: {
          globalScale: 1,
          frameScale: 1,
          userPlayable: true,
          userStepControl: true,
          backgroundColor: [0, 0, 0]
        },
        transforms: [],
        profiles: [],
        closedCaptions: [],
        sources: [],
        audios: [],
        videos: [],
        groups: []
      }
    }
  }
}

export const optionDefaultSetting: CaptionOptionFormat = {
  color: '#ffffff',
  opacity: 0.8,
  bgColor: '#000000',
  fontSize: '1em',
  inlineSize: 'auto',
  writingMode: 'horizontal', // horizontal, vertical
  textAlign: 'left', // left | center | right
  align: 'left', // left | center | right
  verticalAlign: 'middle', // top | middle | bottom
  offsetX: '0',
  offsetY: '0'
}
